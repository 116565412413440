import React from 'react'
import { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import { getStoriesWithStartPath } from '@wh/common/cms/api/storyblokApiClient'
import { CmsReturnProps } from '@wh/common/cms/types/StoryblokComponents'
import { cmsGetStaticProps } from '@bbx/search-journey/common/lib/getStaticPropsForCms'
import { StoryblokBlogpost, StoryblokBlogpostBlok } from '@wh/common/cms/components/pagetypes/StoryblokBlogpost'

const CmsStaticPage: NextPage<CmsReturnProps<StoryblokBlogpostBlok>> & { hasStaticProps: boolean } = (props) => {
    return <StoryblokBlogpost story={props.story} links={props.links} />
}

CmsStaticPage.hasStaticProps = true

export const getStaticProps: GetStaticProps = async (context) => {
    const identifier = ['will', ...(context.params?.seopath as string[])].join('/')
    return cmsGetStaticProps<StoryblokBlogpostBlok>(identifier, context, '1')
}

export const getStaticPaths: GetStaticPaths = async () => {
    const stories = await getStoriesWithStartPath('will')

    const allPaths = stories.map((story) => `/${story.full_slug}`)

    return {
        paths: allPaths,
        fallback: 'blocking',
    }
}

export default CmsStaticPage
